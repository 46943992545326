import styled, { css } from 'styled-components';
import BitImage from '@uc-common/image';

export const Container = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    margin-top: 70px;

    ${tablet()} {
      margin-top: 170px;
    }

    ${laptop()} {
      margin-top: 160px;
    }

    ${desktop()} {
      margin-top: 190px;
    }
  `
);

export const Text = styled.p(
  ({ theme: { tablet, laptop } }) => css`
    font-weight: 500;
    font-size: 10px;
    line-height: 2.4;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #838f9a;

    ${tablet()} {
      font-size: 12px;
      line-height: 24px;
    }

    ${laptop()} {
      font-size: 14px;
    }
  `
);

export const Image = styled(BitImage)`
  margin: 15px 20px;
  max-height: 32px;
  max-width: 70%;
`;
export const ClientsContainer = styled.div(
  ({ theme: { laptop } }) => css`
    margin-top: 15px;
    display: flex;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    ${laptop()} {
      margin-top: 35px;
    }
  `
);
