import React from 'react';
import pandaDocLogo from '@static/icons/pandadoc-bw.svg';
import jasperLogo from '@static/icons/jasper-bw.svg';
import bufferLogo from '@static/icons/buffer.svg';
import gempagesLogo from '@static/icons/gempages-bw.svg';
import zapierLogo from '@static/icons/zapier.svg';
import shogunLogo from '@static/icons/shogun-bw.svg';
import { ClientsContainer, Container, Image, Text } from './Clients.styles';

export const Clients = ({
  className,
  title = '10,000+ developer teams trust uploadcare',
  logos = [
    { src: pandaDocLogo, alt: 'PandaDoc logo' },
    { src: jasperLogo, alt: 'Jasper logo' },
    { src: shogunLogo, alt: 'Shogun logo' },
    { src: bufferLogo, alt: 'Buffer logo' },
    { src: gempagesLogo, alt: 'Gempages logo' },
    { src: zapierLogo, alt: 'Zapier logo' },
  ],
}) => (
  <Container className={className}>
    <Text>{title}</Text>
    <ClientsContainer>
      {logos.map((logo) => (
        <Image key={logo.alt} src={logo.src} alt={logo.alt} />
      ))}
    </ClientsContainer>
  </Container>
);
